import React from 'react'
import PropTypes from 'prop-types'
import "./scss/image_duos.scss"
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const ImageDuos = ({ duos }) => (
  <div className={'image-duos'}>
    {duos.map((item, index) => (
      <div className={'image-duo'} key={index}>
        {typeof item.heading_text !== 'undefined' &&
          <h3 className={'image-duo__heading'}>{item.heading_text}</h3>}
        <div className={'image-duo__images'}>
          {typeof item.image_1 !== 'undefined' &&
            <PreviewCompatibleImage imageInfo={{
              image: item.image_1,
              className: 'image-duo__image image--framed'
            }}
            />}
          {typeof item.image_2 !== 'undefined' &&
            <PreviewCompatibleImage imageInfo={{
              image: item.image_2,
              className: 'image-duo__image image--framed'
            }}
            />}
        </div>
      </div>
    ))}
  </div>
)

ImageDuos.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading_text: PropTypes.string,
      image_1: PropTypes.object,
      image_2: PropTypes.object,
    })
  ),
}

export default ImageDuos

import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo }) => {
  const { image, alt = '', loading = 'lazy', className = '', style = {} } = imageInfo

  if (!!image && !!image.childImageSharp && !!image.childImageSharp.fluid) {
    return (
      <Img className={className} style={style} fluid={image.childImageSharp.fluid} alt={alt} loading={loading} />
    )
  }

  if (!!image && !!image.childImageSharp && !!image.childImageSharp.fixed) {
    return (
      <Img className={className} style={style} fixed={image.childImageSharp.fixed} alt={alt} loading={loading} />
    )
  }

  if (!!image && typeof image === 'string')
    return <div className={`gatsby-image-wrapper ${className}`} style={style}><img src={image} alt={alt} loading={loading} /></div>

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    alt: PropTypes.string,
    loading: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImage

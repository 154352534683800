import React from 'react'
import PropTypes from 'prop-types'
import "./scss/data_points.scss"
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const DataPoints = ({ data }) => (
  <div className={'data-points'}>
    {data.map((item, index) => (
      <div className={'data-point'} key={index}>
        {typeof item.heading_text !== 'undefined' &&
          <h3 className={'data-point__heading'}>{item.heading_text}</h3>}
        {typeof item.image !== 'undefined' &&
          <PreviewCompatibleImage imageInfo={{
            image: item.image,
            style: {width: 80, height: 80 },
            className: 'data-point__icon'
          }}
          />}
        {typeof item.icon !== 'undefined' &&
          <span className={`data-point__icon ${item.icon}`} />}
        <div className={'data-point__data'}>
          <div className={'data-point__number'}>{item.number}</div>
          <div className={'data-point__suffix'}>{item.number_suffix}</div>
        </div>
        {typeof item.footer_text !== 'undefined' &&
          <div className={'data-point__footer'}>{item.footer_text}</div>}
      </div>
    ))}
  </div>
)

DataPoints.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading_text: PropTypes.string,
      footer_text: PropTypes.string,
      number: PropTypes.number,
      number_suffix: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

export default DataPoints

import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import '../components/scss/landing_pages/landing_page_partner.scss'
import { Helmet } from 'react-helmet'
import useSiteMetadata from "../components/SiteMetadata"
import logo from "../img/logo_white.svg"
import DataPoints from "../components/DataPoints"
import ImageDuos from "../components/ImageDuos"
import EmailForm from '../components/EmailForm'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import { Link } from 'react-scroll'
import socialMediaImage from "../../static/img/sales-page-background-02-orig.jpg"

export const LandingPagePartnerTemplate = ({
  title,
  lead,
  audience_stats,
  audience_characteristics,
  audience_connect,
  contact_form,
  helmet,
}) => {

  return (
    <div className="landing-page landing-page--partner">

      {helmet || ''}
      <header className={'page-section page-section--lead'}>
        <div className={'page-section__inner'}>
          <div className={"page-section--lead__logo"}>
            <img src={logo} alt="Canada's National Observer" />
          </div>
          <div className={'page-section--lead__text'}>
            { lead.text }
          </div>
          <div className={'page-section--lead__arrow'}>
            <Link to="audience-stats" smooth={true} offset={-40} duration={475} delay={3}><span className={'fal fa-arrow-down'} /></Link>
          </div>
        </div>
        <PreviewCompatibleImage imageInfo={{
          image: lead.background_image,
          loading: 'eager',
          style: {
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "85%"
          },
          className: 'page-section--lead__image'
        }}
        />
        <div className={'page-section--lead__overlay'} />
      </header>
      <section id="audience-stats" className={'page-section page-section--audience-stats'}>
        <div className={'page-section__inner'}>
          <h2 className={'page-section__heading'}>{audience_stats.heading}</h2>
          <DataPoints data={audience_stats.stats} />
        </div>
      </section>
      <section className={'page-section page-section--audience-characteristics'}>
        <div className={'page-section__inner'}>
          <DataPoints data={audience_characteristics.characteristics} />
        </div>
      </section>
      <section className={'page-section page-section--audience-connect'}>
        <div className={'page-section__inner'}>
          <h2 className={'page-section__heading'}>{audience_connect.heading}</h2>
          <div className={'page-section__subheading'}>{audience_connect.subheading}</div>
          <ImageDuos duos={audience_connect.image_duos} />
        </div>
      </section>
      <section className={'page-section page-section--twitter-feed'}>
        <div className={'page-section__inner'}>
          <div className={'twitter-feed-wrapper'} style={{maxWidth: 650, margin: '0 auto'}}>
            <a className="twitter-timeline" data-height="680" data-theme="light"
               href="https://twitter.com/NatObserver?ref_src=twsrc%5Etfw">Tweets by NatObserver</a>
            <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
          </div>
        </div>
      </section>
      <section className={'page-section page-section--contact-form'}>
        <div className={'page-section__inner'}>
          <div className={'page-section__heading-wrapper'}>
            <PreviewCompatibleImage imageInfo={{
              image: contact_form.image,
            }}
            />
            <h2 className={'page-section__heading'}>{contact_form.heading}</h2>
          </div>
          <div className={'page-section--contact-form__photo'}>
            <PreviewCompatibleImage imageInfo={{
              image: contact_form.photo,
              className: 'image--framed'
            }}
            />
          </div>
          <div className={'page-section--contact-form__form'}>
            <div className={'form-info'}>
              <h3 className={'form-info__heading'}>Contact me</h3>
              <div className={'form-info__name'}>Janel Johnson</div>
              <div className={'form-info__title'}>Subscriptions, partnerships and sponsorships</div>
            </div>
            <EmailForm
              formName={"partner-page-contact-form"}
              successMessage={"Thanks for reaching out, we'll be in touch soon."}
              buttonText={"Send"} />
          </div>
        </div>
      </section>
    </div>
  )
}

LandingPagePartnerTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.shape({
    text: PropTypes.string,
    background_image: PropTypes.object,
  }),
  audience_stats: PropTypes.shape({
    heading: PropTypes.string,
    stats: PropTypes.array,
  }),
  audience_characteristics: PropTypes.shape({
    characteristics: PropTypes.array,
  }),
  audience_connect: PropTypes.shape({
    heading: PropTypes.string,
    image_duos: PropTypes.array,
  }),
  contact_form: PropTypes.shape({
    heading: PropTypes.string,
    image: PropTypes.object,
    photo: PropTypes.object,
  }),
  helmet: PropTypes.object,
}

const LandingPagePartner = ({ data, location }) => {
  const { markdownRemark: page } = data
  const { siteURL } = useSiteMetadata()

  return (
      <Layout location={location}>
        <LandingPagePartnerTemplate
            title={page.frontmatter.title}
            lead={page.frontmatter.lead}
            audience_stats={page.frontmatter.audience_stats}
            audience_characteristics={page.frontmatter.audience_characteristics}
            audience_connect={page.frontmatter.audience_connect}
            contact_form={page.frontmatter.contact_form}
            helmet={
              <Helmet>
                <meta name="description" content="Let's work together." />
                <meta property="og:title" content={page.frontmatter.title} />
                <meta name="og:description" content="Let's work together." />
                <meta property="og:image" content={`${siteURL}${socialMediaImage}`} />
                <meta name="twitter:title" content={page.frontmatter.title} />
                <meta name="twitter:description" content="Let's work together." />
                <meta name="twitter:image" content={`${siteURL}${socialMediaImage}`} />
              </Helmet>
            }
        />
      </Layout>
  )
}

LandingPagePartner.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LandingPagePartner

export const LandingPagePartnerQuery = graphql`
  query LandingPagePartner($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        lead {
          text
          background_image {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 100, toFormat: WEBP) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        audience_stats {
          heading
          stats {
            number
            number_suffix
            icon
            footer_text
          }
        }
        audience_characteristics {
          characteristics {
            heading_text
            number
            number_suffix
            image {
              childImageSharp {
                fixed(width: 160, quality: 100, toFormat: WEBP) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            footer_text
          }
        }
        audience_connect {
          heading
          subheading
          image_duos {
            heading_text
            image_1 {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            image_2 {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100, toFormat: WEBP) {
                ...GatsbyImageSharpFluid_noBase64
              }
              }
            }
          }
        }
        contact_form {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 190, quality: 100, toFormat: WEBP) {
                ...GatsbyImageSharpFluid_noBase64
              } 
            }
          }
          photo {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100, toFormat: WEBP) {
                ...GatsbyImageSharpFluid_noBase64
              } 
            }
          }
        }
      }
    }
  }
`
